import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface FilterByCallAllocation {
  onChange: any;
}

const allocations = [
  {
    key: "User",
    label: "User",
  },
  {
    key: "CallTouchPoint",
    label: "Call Touch Point",
  },
];

const FilterByLength = (props: FilterByCallAllocation): JSX.Element => {
  const [length, setLength] = React.useState("none");
  const {onChange} = props;

  const handleChange = (event: any) => {
    let selectedAllocation = allocations.find((length: any) => {
      return length.key === event.target.value;
    });

    if (selectedAllocation) {
      onChange(selectedAllocation.key);
    } else {
      onChange(null, null);
    }
    setLength(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <Select value={length} onChange={handleChange}>
        <MenuItem key="none" value="none">
          <em>Filter by Allocation</em>
        </MenuItem>
        {allocations.map((allocation: any) => {
          return (
            <MenuItem key={allocation.key} value={allocation.key}>
              {allocation.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FilterByLength;
