import {Component, useEffect, useState} from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";

import VoicemailIcon from "@mui/icons-material/Voicemail";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import SummarizeIcon from "@mui/icons-material/Summarize";

import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";

import FilterByDate, {
  datePeriods,
} from "../../components/FilterByDate/FilterByDate";
import FilterByCallLength from "../../components/FilterByCallLength/FilterByCallLength";

import moment from "moment";

import {IContact} from "../../stores/ContactStore";
import Modal from "../../components/Modal/Modal";
import {Call} from "../../stores/CallStore";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import {getCallRatingWords} from "../../services/callRatingWords";
import {IModel} from "../../types/storeTypes";

import {groupBy, capitalize} from "lodash";
import {CategoryTd, CategoryTr, RuleTd} from "../Settings/StyledComponents";
import {getcallScoreRules} from "../../services/callScoreRules";
import FilterByCallAllocation from "../../components/FilterByCallAllocation/FilterByCallAllocation";

export const StyledCell = styled.td`
  text-align: center;
  &.selected {
    border: 2px solid darkgreen;
  }
`;

export const ScoreTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

interface IProps {
  call: Call;
}

const CallScoring = ({call}: IProps) => {
  const [callScoreRules, setCallScoreRules] = useState<any>();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchCallRatingWords();
  }, []);

  const fetchCallRatingWords = async () => {
    let resp = await getcallScoreRules({
      "filter[kind]": "great",
    });

    const groupedRules = groupBy(resp.data.data, (item: any) => {
      // console.log(item.attributes.category);
      return item.attributes.category;
    });

    // console.log("groupedRules", groupedRules);
    setCallScoreRules(groupedRules);

    setIsLoaded(true);
  };
  if (!isLoaded) {
    return <></>;
  }

  console.log("call.relationships", call.relationships.call_scores.data);
  console.log(call.callScores);
  console.log(call.callScores[0].data.relationships.call_score_rule.data.id);

  const cellClass = (ruleId: any) => {
    const ids = call.callScores.map((score) => {
      return score.data.relationships.call_score_rule.data.id;
    });
    console.log(ids);
    if (ids.includes(ruleId)) {
      return "selected";
    }
  };

  return (
    <>
      <ScoreTable>
        <thead>
          <tr>
            <th></th>
            <th>Value %</th>
            {/* <th>Great</th>
            <th>Great</th>
            <th>Great</th> */}
          </tr>
        </thead>

        {Object.keys(callScoreRules).map((item, i) => {
          return (
            <thead>
              <CategoryTr key={`${i}`}>
                <CategoryTd>{capitalize(item.replace(/_/g, " "))}</CategoryTd>
                <th></th>
                {/* <th></th> */}
              </CategoryTr>
              {callScoreRules[item].map((rule: any, j: number) => (
                <tr key={`${i}-${j}`}>
                  <RuleTd className="input-label">
                    {rule.attributes.name}
                  </RuleTd>
                  <StyledCell className={`input-label ${cellClass(rule.id)}`}>
                    {rule.attributes.value}%
                  </StyledCell>
                  {/* <td className="input-label">
                    {rule.attributes.enabled ? "x" : ""}
                  </td> */}
                </tr>
              ))}
            </thead>
          );
        })}
      </ScoreTable>
    </>
  );
};

const CallDetails = ({call}: IProps) => {
  const [highPotentialWords, setHighPotentialWords] = useState([]);
  const [badRatingWords, setBadRatingWords] = useState([]);
  const [conversation, setConversation] = useState<Array<any>>([]);

  const [isLoaded, setIsLoaded] = useState(false);

  console.log(call.transcription);

  const parseLine = (input: string) => {
    // Define a regular expression to match the "Agent: message" format
    const regex = /^([^:]+):\s*(.+)$/;
    const match = input.match(regex);

    // Check if the input string matches the expected format
    if (match) {
      return {
        member: match[1].trim(),
        line: match[2].trim(),
      };
    } else {
      throw new Error("Input string does not match the expected format");
    }
  };

  useEffect(() => {
    fetchCallRatingWords();
    const res = [] as Array<any>;
    call.transcription.forEach((item: string, index: number) => {
      let obj = parseLine(item) as any;
      if (call.sentiment && call.sentiment.sentences[index]) {
        obj.sentiment = call.sentiment.sentences[index].sentiment;
      }

      res.push(obj);
    });
    console.log(res);
    setConversation(res);
  }, []);

  const fetchCallRatingWords = async () => {
    let resp = await getCallRatingWords({
      "filter[rating_type]": "high_potential",
    });

    setHighPotentialWords(
      resp.data.data.map((word: IModel) => {
        return word.attributes.content.toLowerCase();
      })
    );
    resp = await getCallRatingWords({
      "filter[rating_type]": "bad",
    });
    setBadRatingWords(
      resp.data.data.map((word: IModel) => {
        return word.attributes.content.toLowerCase();
      })
    );
    setIsLoaded(true);
  };

  const highlightText = (
    item: any,
    greenWords: Array<string>,
    redWords: Array<string>
  ) => {
    const regex = new RegExp(
      `\\b(${[...greenWords, ...redWords].join("|")})\\b`,
      "gi"
    );
    const text = item.line;
    const parts = text.split(regex);
    console.log("parts", parts);

    return (
      <>
        {item.sentiment === "positive" && (
          <Tooltip title="Positive">
            <SentimentSatisfiedAltIcon />
          </Tooltip>
        )}
        {item.sentiment === "neutral" && (
          <Tooltip title="Neutral">
            <SentimentNeutralIcon />
          </Tooltip>
        )}
        {item.sentiment === "negative" && (
          <Tooltip title="Negative">
            <SentimentDissatisfiedIcon />
          </Tooltip>
        )}
        <span>
          <span style={{fontWeight: 600, marginRight: 5, marginLeft: 5}}>
            {item.member}:
          </span>
          {parts.map((part: string, index: number) => {
            const lowerPart = part.toLowerCase();
            if (greenWords.includes(lowerPart)) {
              return (
                <span
                  key={index}
                  className="highlight-green"
                  style={{backgroundColor: "lightgreen"}}
                >
                  {part}
                </span>
              );
            } else if (redWords.includes(lowerPart)) {
              return (
                <span
                  key={index}
                  className="highlight-red"
                  style={{backgroundColor: "lightcoral"}}
                >
                  {part}
                </span>
              );
            } else {
              return part;
            }
          })}
        </span>
      </>
    );
  };
  console.log(highPotentialWords, badRatingWords);
  return (
    <>
      {isLoaded &&
        conversation.map((line: any, index: number) => {
          return (
            <Typography key={index} style={{display: "flex"}}>
              {highlightText(line, highPotentialWords, badRatingWords)}
            </Typography>
          );
        })}
    </>
  );
};

interface ITableProps {
  callStore: any;
  setLoading: (loading: boolean) => void;
}

interface IColumn {
  id: string;
  label: string;
  format: any;
  // format: (contact: any) => string
}

const StyledTable = styled(Table)`
  background-color: #fff;

  & .header {
    font-size: 12px;
    color: #6b6a6e;
    text-transform: uppercase;
    white-space: nowrap;
  }

  & tbody > tr {
    & td: {
      color: #6b6a6e;
    }
    &:hover {
      // background-color: #bbef1e;
      // & > td {
      //   color: white;
      // }
    }
  }
`;

const columns = [
  {
    id: "didAllocatable",
    label: "User Name",
    format: (agent: IContact) => {
      return agent.fullName;
    },
  },
  {
    id: "createdAt",
    label: "Date/Time",
    format: (value: string) => {
      return moment(value).format("MM/DD/YY HH:mm");
    },
  },
  {
    id: "callLength",
    label: "Length",
    format: (value: string) => {
      if (!value) {
        return "";
      }
      let seconds = parseInt(value);
      var calculatedMinutes = Math.floor(seconds / 60);
      var calculatedSeconds = seconds - calculatedMinutes * 60;

      var mDisplay =
        calculatedMinutes < 10 ? "0" + calculatedMinutes : calculatedMinutes;
      var sDisplay =
        calculatedSeconds < 10 ? "0" + calculatedSeconds : calculatedSeconds;

      return `${mDisplay}:${sDisplay}`;
    },
  },
  {
    id: "callAudio",
    label: "Audio",
    format: (value: string) => {
      if (!!value) {
        const audio = new Audio(value);
        let play = false;
        const playAudio = () => {
          if (play) {
            play = false;
            audio.pause();
          } else {
            play = true;
            audio.play();
          }
        };
        return <VolumeDownIcon onClick={playAudio} />;
      } else {
        return (
          <Tooltip title="no audio">
            <VolumeMuteIcon />
          </Tooltip>
        );
      }
    },
  },
  {
    id: "from",
    label: "Called From",
    format: (from: string) => {
      if (from.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
        return from.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
      } else {
        return from;
      }
    },
  },
  {
    id: "to",
    label: "Called To",
    format: (to: string) => {
      if (to.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
        return to.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
      } else {
        return to;
      }
    },
  },
  {
    id: "callType",
    label: "Type",
    format: (call_type: string) => {
      if (call_type === "voicemail") {
        return (
          <Tooltip title="voicemail">
            <VoicemailIcon />
          </Tooltip>
        );
      }
      if (call_type === "inbound") {
        return (
          <Tooltip title="inbound">
            <CallReceivedIcon />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title="outbound">
            <CallMadeIcon />
          </Tooltip>
        );
      }
    },
  },
  {
    id: "sentiment",
    label: "Sentiment",
    format: (call: Call, openCallSentimentModal: (call: Call) => void) => {
      if (!call.sentiment) {
        return <></>;
      }

      if (call.sentiment.confidenceScores) {
        return (
          <>
            <Tooltip
              title={
                <>
                  <Typography>
                    Neutral: {call.sentiment.confidenceScores.neutral}
                  </Typography>
                  <Typography>
                    Negative: {call.sentiment.confidenceScores.negative}
                  </Typography>
                  <Typography>
                    Positive: {call.sentiment.confidenceScores.positive}
                  </Typography>
                </>
              }
            >
              <span style={{cursor: "pointer"}}>
                {capitalize(call.sentiment.sentiment)}
              </span>
            </Tooltip>
          </>
        );
      } else {
        return "";
      }
    },
  },
  {
    id: "rating",
    label: "Rating",
    format: (call: Call, openCallTranscriptionModal: (call: Call) => void) => {
      if (!call.transcription) {
        return <></>;
      }
      return (
        <>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              minWidth: "30px",
            }}
            onClick={() => openCallTranscriptionModal(call)}
          >
            <span style={{color: "darkgreen", fontWeight: "600"}}>
              {call.highPotentialWordsCount}
            </span>
            <span style={{color: "#6B6A6E"}}>/</span>
            <span style={{color: "red", fontWeight: "bold"}}>
              {call.badRatingWordsCount}
            </span>
          </Button>
        </>
      );
    },
  },
  {
    //Overall Conversation Score
    id: "score",
    label: "Score",
    format: (call: any, openCallScoreModal: (call: Call) => void) => {
      if (call.score) {
        return (
          <>
            <Button variant="text" onClick={() => openCallScoreModal(call)}>
              {call.score} %
            </Button>
          </>
        );
      }
      return "";
    },
  },
  {
    id: "summary",
    label: "Summary",
    format: (call: Call, openCallTranscriptionModal: (call: Call) => void) => {
      if (!call.transcription) {
        return <></>;
      }
      return (
        <>
          <Button
            variant="text"
            onClick={() => openCallTranscriptionModal(call)}
          >
            <SummarizeIcon />
          </Button>
        </>
      );
    },
  },
];

interface ITablePaginationActions {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: any;
}

function TablePaginationActions(props: ITablePaginationActions) {
  const theme = useTheme();
  const {count, page, rowsPerPage, onPageChange} = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (event: any) => {
    console.log("handleBackButtonClick", page);
    onPageChange(event, page); // -1
  };

  const handleNextButtonClick = (event: any) => {
    console.log("handleNextButtonClick", page);
    onPageChange(event, page + 2); // +1
  };

  const handleLastPageButtonClick = (event: any) => {
    console.log("handleLastPageButtonClick", count, rowsPerPage);
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  };

  return (
    <Box sx={{flexShrink: 0, ml: 2.5}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

interface State {
  showCallTranscriptionModal: boolean;
  showCallSummaryModal: boolean;
  showCallSentimentModal: boolean;
  showCallScoreModal: boolean;
  call: Call | null;
}

@observer
export default class CallsTable extends Component<ITableProps, State> {
  filters: any;
  constructor(props: ITableProps) {
    super(props);
    this.filters = {
      "page[size]": 5,
      "page[number]": 1,
      include: "contact,did_allocation.allocatable",
    };
    this.state = {
      showCallTranscriptionModal: false,
      showCallSummaryModal: false,
      showCallSentimentModal: false,
      showCallScoreModal: false,
      call: null,
    };
  }

  componentDidMount() {
    let selectedPeriod = datePeriods.find((period: any) => {
      return period.key === "last_7_days";
    });
    if (selectedPeriod) {
      this.filters["filter[from_date]"] = selectedPeriod.from();
      this.filters["filter[to_date]"] = selectedPeriod.to();
    }
    this.refreshCalls(this.filters);
  }

  refreshCalls = async (params: any) => {
    params.include =
      "contact,did_allocation.allocatable,call_scores,call_scores.call_score_rule";
    this.props.setLoading(true);
    await this.props.callStore.getCallsAsync(params);
    this.props.setLoading(false);
  };

  handlePeriodChange = (from: any, to: any) => {
    //reset pagination
    this.filters["page[number]"] = 1;
    this.filters["filter[from_date]"] = from;
    this.filters["filter[to_date]"] = to;
    this.refreshCalls(this.filters);
  };

  handleLengthChange = (from: any, to: any) => {
    //reset pagination
    this.filters["page[number]"] = 1;
    this.filters["filter[length.lt]"] = to;
    this.filters["filter[length.gt]"] = from;
    this.refreshCalls(this.filters);
  };

  handleAllocationChange = (allocatable: string) => {
    //reset pagination
    this.filters["page[number]"] = 1;
    this.filters["filter[allocatable_type]"] = allocatable;
    this.refreshCalls(this.filters);
  };

  handleChangePage = (event: any, newPage: number) => {
    this.filters["page[number]"] = newPage;
    this.refreshCalls(this.filters);
  };

  handleChangeRowsPerPage = (event: any) => {
    this.filters["page[size]"] = parseInt(event.target.value, 10);
    this.filters["page[number]"] = 1;
    this.refreshCalls(this.filters);
  };

  openCallTranscriptionModal = (call: Call) => {
    this.setState({call: call, showCallTranscriptionModal: true});
  };

  openCallSummaryModal = (call: Call) => {
    this.setState({call: call, showCallSummaryModal: true});
  };

  openCallSentimentModal = (call: Call) => {
    this.setState({call: call, showCallSentimentModal: true});
  };

  openCallScoreModal = (call: Call) => {
    this.setState({call: call, showCallScoreModal: true});
  };

  closeCallTranscriptionModal = () => {
    this.setState({showCallTranscriptionModal: false});
  };

  closeCallSummaryModal = () => {
    this.setState({call: null, showCallSummaryModal: false});
  };

  closeCallSentimentModal = () => {
    this.setState({call: null, showCallSentimentModal: false});
  };

  closeCallScoreModal = () => {
    this.setState({call: null, showCallScoreModal: false});
  };

  render() {
    const rows = this.props.callStore.calls;
    const rowsPerPage = this.filters["page[size]"];
    const page = this.filters["page[number]"];

    const totalCount = this.props.callStore.meta.recordCount;
    const displayPagination = totalCount > rowsPerPage;

    const tableHeaders = columns.map((column: IColumn) => {
      return (
        <TableCell key={column.id} className="header">
          {column.label}
        </TableCell>
      );
    });

    const tableContent = this.props.callStore.calls.map((row: any) => {
      return (
        <TableRow key={row.id}>
          {columns.map((column) => {
            let value;
            if (column.id === "rating") {
              value = row;
              return (
                <TableCell key={column.id}>
                  {column.format(value, this.openCallTranscriptionModal)}
                </TableCell>
              );
            } else if (column.id === "summary") {
              value = row;
              return (
                <TableCell key={column.id}>
                  {column.format(value, this.openCallSummaryModal)}
                </TableCell>
              );
            } else if (column.id === "sentiment") {
              value = row;
              return (
                <TableCell key={column.id}>
                  {column.format(value, this.openCallSentimentModal)}
                </TableCell>
              );
            } else if (column.id === "score") {
              value = row;
              return (
                <TableCell key={column.id}>
                  {column.format(value, this.openCallScoreModal)}
                </TableCell>
              );
            }
            value = row[column.id];
            return (
              <TableCell key={column.id}>
                {column.format(value, () => {})}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });

    return (
      <>
        <Grid container columnSpacing={{xs: 1, sm: 2, md: 3}}>
          <Grid item xs={2}>
            <FilterByDate
              onChange={this.handlePeriodChange}
              defaultValue="last_7_days"
            />
          </Grid>
          <Grid item xs={2}>
            <FilterByCallLength onChange={this.handleLengthChange} />
          </Grid>
          <Grid item xs={2}>
            <FilterByCallAllocation onChange={this.handleAllocationChange} />
          </Grid>
        </Grid>
        <StyledTable>
          <TableHead>
            <TableRow>{tableHeaders}</TableRow>
          </TableHead>
          <TableBody className="tbody">{tableContent}</TableBody>
          <TableFooter>
            <TableRow>
              {displayPagination && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  colSpan={11}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableFooter>
        </StyledTable>

        <Modal
          open={this.state.showCallTranscriptionModal}
          title="Call Transcription"
          maxWidth="md"
          fullWidth
          onClose={this.closeCallTranscriptionModal}
        >
          {this.state.call && <CallDetails call={this.state.call} />}
        </Modal>

        <Modal
          open={this.state.showCallSummaryModal}
          title="Call Summary"
          maxWidth="md"
          fullWidth
          onClose={this.closeCallSummaryModal}
        >
          {this.state.call && (
            <Typography>{this.state.call.summary}</Typography>
          )}
        </Modal>

        <Modal
          open={this.state.showCallSentimentModal}
          title="Call Sentiment"
          maxWidth="md"
          fullWidth
          onClose={this.closeCallSentimentModal}
        >
          {this.state.call && this.state.call.sentiment && (
            <>
              <Typography>
                Neutral: {this.state.call.sentiment.confidenceScores.neutral}
              </Typography>
              <Typography>
                Negative: {this.state.call.sentiment.confidenceScores.negative}
              </Typography>
              <Typography>
                Positive: {this.state.call.sentiment.confidenceScores.positive}
              </Typography>
            </>
          )}
        </Modal>

        <Modal
          open={this.state.showCallScoreModal}
          title="Call Scoring"
          maxWidth="md"
          fullWidth
          onClose={this.closeCallScoreModal}
        >
          {this.state.call && <CallScoring call={this.state.call} />}
        </Modal>
      </>
    );
  }
}
